import Image from "next/image";
import Link from "next/link";
import { imageBasePath } from "lib/utils/imageUtils";
import withLogin from "components/Login/withLogin";
import { flowTypes } from "components/Login/LoginConstants";
import withWindowDimensions from "components/shared/withWindowDimensions";
import { trackClick } from "scripts/segment";
import { pageName } from "lib/utils/events_utils";
import { logEvent } from "components/Analytics/google-analytics";
import { useRouter } from "next/router";
import { useFetchUser } from "lib/user";
import { pageRoutes } from "constant/routes";
import { useEffect, useState } from "react";
import { requestFooterData } from "components/MainFooter/MainFooterApis";
import parse from "html-react-parser";

const triggerSegmentClick = (contentName, widgetName = "Footer") => {
  trackClick(pageName.HOME_PAGE, {
    widgetName,
    widgetFormat: "Banner",
    contentName,
    contentFormat: "Link",
  });
};

const Divider = () => (
  <div className="border-[1px] border-[#686868] w-full my-6" />
);

const ContactSection = ({ isMobileSized, router, companyContacts }) => (
  <div className="flex flex-col gap-1">
    {!isMobileSized && (
      <p className="font-bold text-normal text-[#1B1D6A] text-[14px] md:leading-6">
        Contact us
      </p>
    )}
    <a
      className="text-[12px] text-[#565660] leading-[21px]"
      href={companyContacts.link.phone}
      onClick={() => {
        triggerSegmentClick(companyContacts.link.phone, "Footer-Contact");
        logEvent(
          "Contact",
          "Clicked on Talk to us in website footer",
          `${router?.asPath}`
        );
      }}
    >
      Call us on: {companyContacts.display.phone} <p>(Between 10AM to 7PM)</p>
    </a>
    <a
      className="text-[12px] text-[#565660] leading-[21px]"
      href={companyContacts.link.whatsApp}
      target="_blank"
      onClick={() => {
        triggerSegmentClick(companyContacts.link.whatsApp, "Footer-Contact");
        logEvent(
          "Contact",
          "Clicked on whatsapp to us in website footer",
          `${router?.asPath}`
        );
      }}
      rel="noreferrer"
    >
      WhatsApp us on: {companyContacts.display.whatsApp}
    </a>
    <a
      className="text-[12px] text-[#565660] leading-[21px]"
      href={companyContacts.link.email}
      onClick={() => {
        triggerSegmentClick(companyContacts.link.email, "Footer-Contact");
        logEvent(
          "Contact",
          "Clicked on Email us in website footer",
          `${router?.asPath}`
        );
      }}
    >
      Mail us on: <p>{companyContacts.display.email}</p>
    </a>
  </div>
);

const MainFooter = ({
  isMobileSized,
  initLogin,
  isBottomMarginRequired = false,
}) => {
  const [footerContent, setFooterContent] = useState(null);
  const { user } = useFetchUser();
  const router = useRouter();
  const handleSignInPopUp = (flowType) => {
    initLogin(true, "", flowType);
  };

  const {
    companyContacts,
    footerAddresses,
    footerLinks,
    minLoanAmountByCountryNames,
    currentEligibleStates,
  } = footerContent || {};

  useEffect(() => {
    const fetchFooterData = async () => {
      const footerData = await requestFooterData();
      if (footerData) setFooterContent(footerData);
    };

    fetchFooterData();
  }, []);

  if (!footerContent) return null;

  return (
    <>
      <footer
        className={`bg-[#F3F3F3] ${
          isBottomMarginRequired ? "mb-[40px] md:mb-[90px]" : ""
        } lg:mb-0 ${isMobileSized ? "py-5 px-[22px]" : "p-[36px]"}`}
      >
        <div className={!isMobileSized ? "wrapper" : ""}>
          <div className="flex flex-col gap-6">
            {/* logo */}
            <div className="flex items-center gap-2">
              <Image
                height={50}
                width={200}
                onClick={() => {
                  if (window) {
                    window.open("https://leapfinance.com", "_self");
                  }
                }}
                className="logo cursor-pointer"
                src={`${imageBasePath}/assets/images/company-logo/logo.svg`}
                alt="Leap Finance Logo"
              />
            </div>
            <div>
              <button
                type="button"
                onClick={() => {
                  trackClick(pageName.HOME_PAGE, {
                    widgetName: "Footer",
                    widgetFormat: "Footer",
                    contentName: "Start Your Journey",
                    contentFormat: "Button",
                  });
                  user
                    ? router.push(pageRoutes.INSCHOOL_DASHBOARD)
                    : handleSignInPopUp(flowTypes.INSCHOOL);
                }}
                className="mb-4 shadow-20 text-center rounded text-[#fff] bg-lightblue-to-blue inline-block text-sm leading-6 font-bold px-6 py-3"
              >
                Start Your Journey
              </button>
            </div>

            {/* contact-mobile */}
            {companyContacts && (
              <div className="md:order-4">
                <ContactSection
                  router={router}
                  companyContacts={companyContacts}
                />
              </div>
            )}

            {/* Social footerLinks */}
            <div className="flex flex-col gap-1">
              <p className="font-bold text-normal text-[#443EFF] text-[14px] md:leading-6">
                Follow us on:
              </p>
              <div className="flex gap-2 mt-2">
                {footerLinks.socialMedia.map((element, index) => (
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={element.link}
                    key={`social-media-${index}`}
                    onClick={() =>
                      triggerSegmentClick(element.contentName + " icon")
                    }
                  >
                    <Image
                      src={element.icon}
                      alt={element.contentName + " icon"}
                      width={30}
                      height={30}
                    />
                  </a>
                ))}
              </div>
            </div>
          </div>

          <div className="flex-1 break-words">
            <div
              className={`grid ${
                !isMobileSized ? "md:grid-cols-4" : "grid-cols-2 gap-5 mt-8"
              }`}
            >
              {/* Company */}
              <div className="flex flex-col gap-1">
                <p className="font-bold text-normal text-[#1B1D6A] text-[14px] md:leading-6">
                  Company
                </p>
                {footerLinks.company.map((element, index) => (
                  <Link
                    href={{
                      pathname: element.link,
                      query: { clickId: element.clickId },
                    }}
                    key={`company-${index}`}
                    className="text-[12px] text-[#565660] leading-[21px]"
                  >
                    {element.text}
                  </Link>
                ))}

                {!isMobileSized && (
                  <div className="flex flex-col mt-2">
                    <p className="font-bold text-normal text-[#1B1D6A] text-[14px] md:leading-6">
                      Our Partners
                    </p>
                    {footerLinks.partners.map((element, index) => (
                      <Link
                        href={element.link}
                        key={`partners-${index}`}
                        className="text-[12px] text-[#565660] leading-[21px]"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {element.contentName}
                      </Link>
                    ))}
                  </div>
                )}
              </div>

              {/* Products */}
              <div className="flex flex-col gap-1">
                <p className="font-bold text-normal text-[#1B1D6A] text-[14px] md:leading-6">
                  Our Products
                </p>
                {footerLinks.products.map((element, index) => (
                  <Link
                    href={element.link}
                    key={`products-${index}`}
                    className="text-[12px] text-[#565660] leading-[21px]"
                    target="_blank"
                    onClick={() => triggerSegmentClick(element.event)}
                  >
                    {element.text}
                  </Link>
                ))}
              </div>

              <div className="flex flex-col gap-1">
                <p className="font-bold text-normal text-[#1B1D6A] text-[14px] md:leading-6">
                  Resources
                </p>
                {footerLinks.resources.map((element, index) => (
                  <Link
                    href={
                      element.clickId
                        ? { pathname: element.link, clickId: element.clickId }
                        : element.link
                    }
                    key={`resources-${index}`}
                    className="text-[12px] text-[#565660] leading-[21px]"
                    onClick={() =>
                      element.event && triggerSegmentClick(element.event)
                    }
                  >
                    {element.text}
                  </Link>
                ))}
              </div>

              <div className="flex flex-col gap-1">
                <Link
                  href={footerLinks.testimonialLink}
                  className="font-bold text-normal text-[#1B1D6A] text-[14px] md:leading-6"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => {
                    triggerSegmentClick("Testimonials");
                    logEvent("LF-Home", "Testimonials", "Footer");
                  }}
                >
                  Testimonials
                </Link>
                <Link
                  href={footerLinks.careersLink}
                  className="font-bold text-normal text-[#1B1D6A] text-[14px] md:leading-6"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Careers
                </Link>
                <div className="flex flex-col gap-1">
                  <p className="font-bold text-normal text-[#1B1D6A] text-[14px] md:leading-6">
                    Legal
                  </p>
                  {footerLinks.legal.map((element, index) => (
                    <Link
                      href={{
                        pathname: element.link,
                        query: { clickId: element.clickId },
                      }}
                      key={`legal-${index}`}
                      className="text-[12px] text-[#565660] leading-[21px]"
                      target="_blank"
                    >
                      {element.text}
                    </Link>
                  ))}
                </div>
              </div>
            </div>

            <Divider />

            <div>
              {/* Locations */}
              <div className={!isMobileSized ? "grid md:grid-cols-4" : ""}>
                {footerAddresses.map((element, id) => (
                  <div key={`footer-address-${id}`}>
                    <div className="flex flex-col gap-1">
                      <p className="font-bold text-normal text-[#1B1D6A] text-[14px] md:leading-6">
                        {element?.heading}
                      </p>
                      <p
                        className={`text-[13px] text-[#565660] leading-[21px] ${!isMobileSized &&
                          "max-w-[148px]"}`}
                      >
                        {parse(element?.address)}
                      </p>
                    </div>
                    {isMobileSized ? <Divider /> : null}
                  </div>
                ))}
                {/* Our Partners */}
                <div className="flex flex-col gap-1 md:hidden">
                  <p className="font-bold text-normal text-[#1B1D6A] text-[14px] md:leading-6">
                    Our Partners
                  </p>
                  {footerLinks.partners.map((element, index) => (
                    <Link
                      href={element.link}
                      key={`partners-${index}`}
                      className="text-[12px] text-[#565660] leading-[21px]"
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={() =>
                        triggerSegmentClick(
                          element.contentName,
                          "Footer-Partners"
                        )
                      }
                    >
                      {element.contentName}
                    </Link>
                  ))}
                </div>
              </div>
            </div>
            <Divider />

            {/*  Eligible States */}
            <div
              className="grid gap-6 text-[12px] text-[#565660] leading-[21px]"
              id="supported-states-footer-id"
            >
              <p>© 2023, Leap Finance Inc.</p>
              <p>
                Current Eligible States: {currentEligibleStates?.join(", ")}
              </p>
              <p>
                Leap Finance loans are made by Leap Finance Inc. Terms and
                conditions apply. To qualify for a Leap Finance loan you must be
                attending college in an Eligible State and must meet Leap
                Finance's underwriting requirement.
              </p>
              <div>
                {minLoanAmountByCountryNames.map((element, index) => (
                  <p key={`country-name-${index}`}>{element}</p>
                ))}
              </div>
            </div>
          </div>
        </div>
      </footer>

      <style jsx>
        {`
          .logo {
            filter: brightness(0) invert(1);
            margin-left: -4px;
            height: 50px;
          }

          .wrapper {
            max-width: 1120px;
            margin: auto;
            display: flex;
            gap: 80px;
          }
        `}
      </style>
    </>
  );
};

export default withLogin(withWindowDimensions(MainFooter));
