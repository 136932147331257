import axios from "axios";

export const requestFooterData = async () => {
  try {
    const res = await axios.get(
      `${process.env.KnowledgeBaseURL}/constants?key=lf-footer-content`
    );

    return res?.data?.[0]?.jsonValue;
  } catch (error) {
    console.log(error);
  }
};
